window.VideoTestimonialSlider = (randomId) => {
    return {
        swiper: null,
        init() {
            setTimeout(() => {
                this.swiper = new Swiper('.swiper-container'+randomId, {
                    preloadImages: false,
                    lazy: true,
                    loop: true,
                    spaceBetween: 10,
                    slidesPerView: 1,
                    speed: 400,
                    navigation: {
                        nextEl: this.$refs.swiperNext,
                        prevEl: this.$refs.swiperPrev,
                    },
					breakpoints: {
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
					},
                });
            }, 0);
        },
    };
};